import React from 'react';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    StateStoring,
    Summary,
    TotalItem,
    Selection
} from 'devextreme-react/data-grid';
import { appInfo } from '../../app-info';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog'
import { Editing } from 'devextreme-react/tree-list';
import { SelectBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import CheckBox from 'devextreme/ui/check_box'



export default class extends React.Component {


    dataSource = new DataSource({
        store: new CustomStore({
            key: 'Id',
            load: s => {
                return fetch(appInfo.apiUrl() + 'Rilevazioni', { ...appInfo.headers() })
                    .then(r => {
                        if (r.status !== 200) {
                            notify('Errore nel caricamento delle rilevazioni', 'error');
                        }
                        else {
                            return r.json();
                        }
                    }
                    );
            },
            remove: id => {
                return fetch(appInfo.apiUrl() + 'Rilevazioni/' + id, { 'method': 'DELETE', ...appInfo.headers() })
                    .then(r => {
                        if (r.status !== 200) {
                            notify('Errore nel caricamento delle rilevazioni', 'error');
                        }
                        else
                            return r.json();
                    }
                    );

            },
            update: () => { }

        })
    });



    codiceDataSourceConfiguration = (id) => {
        // var id = 0;
        // if (options.data)
        //     id = options.data.IdCommessa;
        return {
            // store: new CustomStore({
            loadMode: 'processed',
            key: 'id',
            load: loadOptions => {
                let url = new URL(appInfo.apiUrl() + 'destinazioni/commesse/' + id);
                if (loadOptions !== undefined && loadOptions !== null) {
                    let params = { s: loadOptions.searchValue };
                    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
                }
                return fetch(url, appInfo.headers())
                    .then((response) => {
                        if (response.status !== 200) {
                            return response.json()
                                .then((r) => {
                                    return Promise.reject(r)
                                });
                        }
                        return response.json();
                    });

            },
            byKey: key => {
                let url = new URL(appInfo.apiUrl() + 'destinazioni/sottocommesse/' + key);
                return fetch(url, appInfo.headers())
                    .then((response) => {
                        if (response.status !== 200) {
                            return response.json()
                                .then((r) => {
                                    return Promise.reject(r)
                                });
                        }
                        return response.json();
                    });

            }/*,
                filter: options.data ? ["StateID", "=", options.data.StateID] : null*/
            // })
        };
    }



    state = { clearFilter: false };

    grdRilevazioni = React.createRef();
    btnApprova = React.createRef();

    updatingCode = false;


    editClick = e => {
        console.log(e);
        var key = null;
        var data = null;
        if (e.key) {
            key = e.key;
            data = e.data;
        }
        else if (e.row && e.row.key) {
            key = e.row.key;
            data = e.row.data;
        }


        if (key && data.CanEdit) {
            this.props.history.push('/timesheet/' + key);
        }
        else {
            notify('Accesso negato', 'error');
        }

    }

    cloneClick = e => {
        if (e.row && e.row.key && e.row.data.CanClone) {
            this.props.history.push('/timesheet/' + e.row.key + '?clone=true');
        }
    }

    checkClick = (e) => {
        if (e.row && e.row.key && e.row.data.CanApprove) {
            confirm('Approvare la rilevazione corrente?', 'Approvazione').done(
                dr => {
                    if (dr) {
                        this.approve(e.row.key, true);
                    }
                });
        }
    }


    approve(id, reload) {
        fetch(appInfo.apiUrl() + 'Rilevazioni/' + id + '/Approva',
            { ...appInfo.headers() })
            .then(
                r => {
                    if (r.status === 200) {
                        notify('Rilevazione approvata con successo', 'success');
                        if (reload)
                            this.dataSource.reload();
                    }
                    else {
                        r.json().then(e => notify('Impossibile approvare: ' + e.ExceptionMessage, 'error'));
                    }
                }
            )
            .catch(e =>
                notify('Errore durante l\'approvazione della rilevazione', 'error')
            );
    }


    rowPrepared(e) {
        if (e.rowType === 'data') {
            if (e.data.Eliminato) e.rowElement.style.textDecoration = 'line-through';
            if (e.data.Approvato) e.rowElement.style.fontWeight = 'bold';


        }
    }

    cellPrepared(e) {

        if (e.rowType === 'data' && e.column.command === 'select' && !e.data.CanApprove) {
            let instance = CheckBox.getInstance(e.cellElement.firstElementChild);            
            instance.option("disabled", true);
            instance.off();
        }
    }

    onSelectionChanged(e) {
        var selectedRows = this.grdRilevazioni.current.instance.getSelectedRowsData();
        if (e.currentDeselectedRowKeys.length === 0) {
            var disabledKeys = [];
            selectedRows.forEach(element => {
                if (!element.CanApprove) {
                    disabledKeys.push(element.Id);
                }
            });
            e.component.deselectRows(disabledKeys);

        }

        selectedRows = this.grdRilevazioni.current.instance.getSelectedRowsData();
        this.btnApprova.current.instance.option('visible', (selectedRows.length > 0) && this.isResponsabile());
    }

    assegnaCodice(grid, cell, IdSottocommessa) {
        fetch(appInfo.apiUrl() + 'Rilevazioni/' + cell.data.Id + '/AssegnaCodice',
            { method: 'POST', ...appInfo.headers(), body: IdSottocommessa }).then(
                r => {
                    if (r.status === 200) {
                        r.json().then(m => {
                            notify('Codice modificato', 'success');
                            this.updatingCode = false;
                            cell.setValue(m.Sottocommessa.id, m.Sottocommessa.codice);
                            grid.instance.refresh();
                        }
                        );
                    }
                    else {
                        throw (r);
                    }
                }
            ).catch(
                e => e.json().then(l => notify(l.ExceptionMessage, 'error'))
            );
    }

    batchApprove() {
        var selectedRows = this.grdRilevazioni.current.instance.getSelectedRowsData();
        for (var i = 0; i < selectedRows.length; i++) {
            this.approve(selectedRows[i].Id, i === selectedRows.length - 1);
        }
        this.grdRilevazioni.current.instance.refresh();
    }

    allowDeleting(e) {
        return e.row.data.CanDelete;
    }

    allowUpdating(e) {
        return e.row.data.CanApprove;
    }

    approveVisible(e) {
        return e.row.data.CanApprove;
    }

    editVisible(e) {
        return e.row.data.CanEdit;
    }
    cloneVisible(e) {
        return e.row.data.CanClone;
    }
    deleteVisible(e) {
        return e.row.data.CanDelete;
    }

    isResponsabile() {
        return JSON.parse(window.localStorage.me).Responsabile;
    }

    hasDeleghe() {
        return JSON.parse(window.localStorage.me).Deleghe;
    }



    componentDidMount() {
        // this.btnRemoveFilter.current.instance.option('visible',this.grdRilevazioni.current.instance.getCombinedFilter(true)!=null);

    }

    customStateLoad() {
        try {
            this.loadingState = true;
            var stateStoring = this.grdRilevazioni.current.instance.option('stateStoring');
            var loadedState = localStorage.getItem(stateStoring.storageKey);
            if (loadedState) {
                loadedState = JSON.parse(loadedState);
            }
            this.loadingState = false;
            this.btnApprova.option('visible', false);
            return loadedState;
        } catch (e) {
            return null;
        }

    }
    customStateSave(gridState) {
        this.loadingState = true;
        var stateStoring = this.grdRilevazioni.current.instance.option('stateStoring');
        gridState.selectedRowKeys = [];
        localStorage.setItem(stateStoring.storageKey, JSON.stringify(gridState));
        this.loadingState = false;
    }



    sottocommessaCell(cellData) {
        if (cellData.data.Sottocommessa == null) {
            return (<div>{cellData.data.Codice}</div>);
        }
        else {
            return (<div>{cellData.data.Sottocommessa.codice}</div>);
        }

    }

    sottocommessaEditCell(cellData) {
        if (cellData.data.Commessa == null) {
            return (<div>{cellData.data.Codice}</div>);
        }
        else if (!cellData.data.CanEdit) {
            return this.sottocommessaCell(cellData);
        }
        else {
            return (<div><SelectBox searchEnabled={true} onValueChanged={this.onValueChanged.bind(this, cellData)} dataSource={this.codiceDataSourceConfiguration(cellData.data.IdCommessa)} valueExpr="id" displayExpr="codice" value={cellData.data.IdSottocommessa} ></SelectBox></div>);
        }
    }

    onValueChanged(cell, e) {

        if (!this.updatingCode) {
            this.updatingCode = true;
            if (e.value != null && e.value !== 0 && e.value !== e.previousValue) {
                this.assegnaCodice(this.grdRilevazioni.current, cell, e.value);
                cell.data.IdSottocommessa = e.value;
            }
        }
    }

    render() {
        return (
            <React.Fragment>
                <h2 className={'content-block'}>Rilevazioni</h2>

                <DataGrid
                    ref={this.grdRilevazioni}
                    className={'dx-card wide-card'}
                    dataSource={this.dataSource}
                    showBorders={false}
                    focusedRowEnabled={true}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    onRowDblClick={this.editClick}
                    onRowPrepared={this.rowPrepared}
                    onCellPrepared={this.cellPrepared}
                    onSelectionChanged={this.onSelectionChanged.bind(this)}

                >
                    <Selection
                        mode="multiple"
                        selectAllMode='allPages'
                        showCheckBoxesMode={this.isResponsabile() ? 'always' : 'none'}
                    />
                    <StateStoring enabled={true} type={'custom'} storageKey={'rilevazioni_state'}
                        customLoad={this.customStateLoad.bind(this)}
                        customSave={this.customStateSave.bind(this)}
                    />
                    <Editing mode={'cell'} allowDeleting={this.allowDeleting} allowUpdating={true} allowEditing={true} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true} showInfo={true} />
                    <FilterRow visible={true} />
                    <Column type={'buttons'}
                        buttons={[
                            {
                                hint: 'Approva',
                                icon: 'check',
                                onClick: this.checkClick,
                                visible: this.approveVisible.bind(this)
                            },
                            {
                                hint: 'Modifica',
                                icon: 'edit',
                                onClick: this.editClick,
                                visible: this.editVisible
                            }, {
                                hint: 'Duplica',
                                icon: 'unselectall',
                                onClick: this.cloneClick,
                                visible: this.cloneVisible
                            }, 'delete']} >

                    </Column>

                    <Column dataField={'Id'} visible={false} />
                    <Column
                        dataField={'Data'} dataType={'date'} format={'dd/MM/yyyy'} allowEditing={false}
                    />
                    <Column
                        dataField={'Ore'}
                        caption={'Ore'}
                        datatype={'decimal'}
                        format={'#,##0.00'}
                        allowEditing={false}
                    />
                    <Column
                        dataField={'Persona.nominativo'}
                        caption={'Personale'}
                        visible={this.isResponsabile()}
                        allowEditing={false}
                    />
                     <Column
                        dataField={'Persona.azienda'}
                        caption={'Azienda'}
                        visible={this.hasDeleghe()}
                        allowEditing={false}
                    />
                    <Column
                        dataField={'Qualifica.descrizione'}
                        caption={'Qualifica'}
                        visible={this.isResponsabile()}
                        allowEditing={false}
                    />
                    <Column
                        dataField={'Causale.descrizione'}
                        caption={'Causale'}
                        allowEditing={false}
                    />

                    <Column
                        dataField={'Destinazione'}
                        caption={'Destinazione'}
                        allowEditing={false}
                    />
                    <Column
                        dataField={'Sottocommessa.descrizione'}
                        caption={'Codice'}
                        allowEditing={true}
                        cellRender={this.sottocommessaCell.bind(this)}
                        editCellRender={this.sottocommessaEditCell.bind(this)}
                    >
                    </Column>

                    <Column
                        dataField={'Descrizione'}
                        caption={'Descrizione'}
                        allowEditing={false}
                    />
                    <Column dataField={'OreEffettive'} visible={false} allowEditing={false}>

                    </Column>
                    <Column
                        dataField={'Attrezzatura.codice'}
                        caption={'Mezzo'}
                        allowEditing={false}
                    />
                    <Summary>
                        <TotalItem
                            column={'OreEffettive'}
                            summaryType={'sum'}
                            displayFormat={'{0}'}
                            valueFormat={'#,##0.00'}
                            showInColumn={'Ore'} />
                    </Summary>

                </DataGrid>
                <Button ref={this.btnApprova}
                    width={120}
                    text="Approva"
                    type="default"
                    visible={this.state.batchApprove}
                    onClick={this.batchApprove.bind(this)}
                />
            </React.Fragment>
        );
    }
}