import React from 'react';
import TextBox from 'devextreme-react/text-box';
import ValidationGroup from 'devextreme-react/validation-group';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import Button from 'devextreme-react/button';
import LoadIndicator from 'devextreme-react/load-indicator';
import './login-form.scss';
import { appInfo } from '../../app-info';
import { AuthConsumer } from 'react-check-auth';


export default class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.validate = React.createRef();
    this.pin=null;
   // this.state = {      pin: '',      consumer: null    };
  }

  render() {
    //const { pin } = this.state;
    return (
      <AuthConsumer>
        {
          ({ isLoading, userInfo, error, refreshAuth }) => {
            return (<ValidationGroup ref={this.validate}>
              <div className={'login-header'}>
                <div className={'title'}>{appInfo.title}</div>
                <div>Accedi all'applicazione</div>
              </div>
              <div className={'dx-field'}>
                <TextBox
                  mode={'password'}
                  //defaultValue={this.pin}
                  onValueChanged={this.pinChanged}
                  placeholder={'PIN'}
                  width={'100%'}
                  onEnterKey={this.onEnterKey}
                  disabled={this.isLoading}
                >
                  <Validator>
                    <RequiredRule message={'PIN is required'} />
                  </Validator>
                </TextBox>

              </div>
              <div className={'dx-field'}>

                <Button id={'loginButton'} disabled={this.isLoading} text={'Login'} stylingMode={'contained'} type={'normal'} width={'100%'} onClick={(args) => this.onLoginClick(args, refreshAuth)} useSubmitBehavior={true}>
                  <span className={'dx-button-text'}>{isLoading ? 'Login...' : 'Login'}</span>
                  <LoadIndicator height={20} width={20} className={'button-indicator'} visible={isLoading} />
                </Button>

              </div>
            </ValidationGroup>)
          }
        }
      </AuthConsumer>)
  };


  pinChanged = e => {
    //this.setState({ pin: e.value });
    this.pin=e.value;
  };

  onEnterKey = (args) => {
    this.onLogin(this.validate.current.instance);
  }

  onLoginClick = (args, refreshAuth) => {
    this.onLogin(args.validationGroup);

  };

  onLogin(validationGroup) {
    if (!validationGroup.validate().isValid) {
      return;
    }
    //const { pin } = this.state;
    this.props.onLoginClick(this.pin);
    validationGroup.reset();
  }

}
