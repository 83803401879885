import React from 'react';
import ContextMenu from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';
import './user-panel.scss';
import { AuthConsumer } from 'react-check-auth';
export default class UserPanel extends React.Component {
  render() {
    const { menuMode, menuItems } = this.props;
    return (
      <AuthConsumer>
        {
          ({ userInfo }) => {
            return (
              <div className={'user-panel'}>
                <div className={'user-info'}>
                  {/* <div className={'image-container'}>
                    <div className={'user-image'} />
                  </div> */}
                  <div className={'user-name'}>{userInfo.nominativo}</div>
                </div>

                {menuMode === 'context' && (
                  <ContextMenu
                    items={menuItems}
                    target={'.user-button'}
                    showEvent={'dxclick'}
                    width={170}
                    position={{ my: 'top center', at: 'bottom center' }}
                    cssClass={'user-menu'}                    
                  />
                )}
                {menuMode === 'list' && (
                  <List className={'dx-toolbar-menu-action'} items={menuItems} />
                )}
              </div>)
          }
        }
      </AuthConsumer>
    );
  }
}
