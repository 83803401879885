import React, { useState } from 'react';
import './timesheet.scss';
import { Form, Item, Label, RequiredRule, GroupItem } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import { Component } from 'devextreme-react/core/component';
import { appInfo } from '../../app-info';
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import { LoadPanel } from 'devextreme-react/load-panel';
import { addHours, roundTo30 } from '../../utils/date-utils';
import { Route } from 'devextreme-react/map';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog'



class TimeSheetPage extends Component {



  constructor(props) {
    super(props);




    this.defaultRilevazione = {
      Id: 0,
      Data: new Date(),
      IdPersonale: '',
      Persona: '',
      IdCausale: '',
      Causale: '',
      IdQualifica: '',
      Qualifica: '',
      Ore: 1,

      AssenzaDa: roundTo30(new Date()),
      AssenzaA: roundTo30(addHours(new Date(), 1)),

      IdTipoDestinazione: '',
      TipoDestinazione: '',
      IdCdC: '',
      CdC: '',
      IdCommessa: '',
      Commessa: '',
      IdSottocommessa: '',
      Sottocommessa: '',
      IdCentroDiLavoro: '',
      CentroDiLavoro: '',

      Indefinito: false,

      IdLavoro: '',
      Lavoro: '',
      Descrizione: '',
      IdAttrezzatura: '',



      Approvato: false,
      Eliminato: false,
      CanApprove: false,
      CanEdit: true,
      CanDelete: true,
      CanClone: true,
      Updating: false

    };

    //Rilevazione
    this.rilevazione = {};
    this.loading = false;

    Object.assign(this.rilevazione, this.defaultRilevazione);
    if (this.props.match.params.id) {
      this.rilevazione.Id = this.props.match.params.id;
    }
    //Refs
    this.form = React.createRef();
    this.loadPanel = React.createRef();





    //Collections
    this.personale = this.fromStorage('personale');
    this.causali = this.fromStorage('causali');
    this.qualifiche = this.fromStorage('qualifiche');
    this.destinazioni = this.fromStorage('destinazioni');
    this.lavori = this.fromStorage('lavori');
    this.attrezzature = this.fromStorage('attrezzature');

    //Data sources
    this.personaleDataSource = this.createDataSource('personale', (r) => {
      this.personale = r;
      if (this.setDefaultPersonale())
        this.updateForm();
    });
    this.causaliDataSource = this.createDataSource('causali', (r) => this.causali = r);
    this.attrezzatureDataSource = this.createDataSource('attrezzature', (r) => this.attrezzature = r);
    this.lavoriDataSource = this.createDataSource('lavori', (r) => this.lavori = r);
    this.destinazioniDataSource = this.createDataSource('destinazioni', (r) => this.destinazioni = r);
    this.qualificheDataSource = this.createDataSource('qualifiche', (r) => this.qualifiche = r);
    /*if(!this.qualifiche) 
      this.qualifiche = r;
      this.personaleDataSource.reload()
    });          */

    this.qualifichePersonale = new DataSource({
      store: new CustomStore({
        load: (s) => {
          if (this.rilevazione.Persona) {
            //var qualifiche = this.rilevazione.Persona.qualifiche.length <= 1 ? this.qualifiche : this.rilevazione.Persona.qualifiche;
            var qualifiche = this.rilevazione.Persona.qualifiche;
            if (s && s.searchValue)
              return qualifiche.filter((p) => p.descrizione.match(new RegExp(s.searchValue + ".*", 'igm')))
            else
              return qualifiche;
          }
          else {
            return [];
          }
        },
        byKey: (s) => {
          if (this.rilevazione.Persona)
            return this.rilevazione.Persona.qualifiche.find(function (q) {
              return q.id === s;
            });
          else
            return null;
        }

      })
    });
    this.commesseLookup = new DataSource({
      store: new CustomStore({
        load: (s) => {
          if (s.searchValue) {
            return this.fetchLookUp('destinazioni', this.rilevazione.IdTipoDestinazione, s);
          }
          else if (this.rilevazione && this.rilevazione.TipoDestinazione && this.rilevazione.TipoDestinazione.Tipo === "C") {
            return this.fetchLookUp('destinazioni', this.rilevazione.IdTipoDestinazione, { searchValue: '*' });
          }
        },
        byKey: (s) => {
          if (s &&
            this.rilevazione.IdCommessa !== 0 &&
            this.rilevazione.IdTipoDestinazione !== 0
          ) {
            return this.fetchLookUp('destinazioni', this.rilevazione.IdTipoDestinazione + '/' + this.rilevazione.IdCommessa);
          }
        }

      })
    });
    this.imbarcazioniLookup = new DataSource({
      store: new CustomStore({
        load: (s) => {
          if (s.searchValue) {
            return this.fetchLookUp('destinazioni', this.rilevazione.IdTipoDestinazione, s);
          }
          else if (false && this.rilevazione && this.rilevazione.TipoDestinazione && this.rilevazione.TipoDestinazione.Tipo === "I") {
            return this.fetchLookUp('destinazioni', this.rilevazione.IdTipoDestinazione, { searchValue: '*' });
          }
        },
        byKey: (s) => {
          if (s &&
            this.rilevazione.IdImbarcazione !== 0 &&
            this.rilevazione.IdTipoDestinazione !== 0
          ) {
            return this.fetchLookUp('destinazioni', this.rilevazione.IdTipoDestinazione + '/' + this.rilevazione.IdImbarcazione);
          }
        }

      })
    });
    this.cdcLookup = new DataSource({
      store: new CustomStore({
        load: (s) => {
          if (s.searchValue) {
            return this.fetchLookUp('destinazioni', this.rilevazione.IdTipoDestinazione, s);
          }
          else if (this.rilevazione && this.rilevazione.TipoDestinazione && this.rilevazione.TipoDestinazione.Tipo === "D") {
            return this.fetchLookUp('destinazioni', this.rilevazione.IdTipoDestinazione, { searchValue: '*' });
          }
        },
        byKey: (s) => {
          if (s &&
            this.rilevazione.IdCdC !== 0 &&
            this.rilevazione.IdTipoDestinazione !== 0
          ) {
            return this.fetchLookUp('destinazioni', this.rilevazione.IdTipoDestinazione + '/' + this.rilevazione.IdCdC);
          }
        }

      })
    });

    this.sottodestinazioniLookup = new DataSource({
      store: new CustomStore({
        key: 'id',
        load: (s) => { if (this.rilevazione.IdCommessa !== 0) return this.fetchLookUp('destinazioni/commesse', this.rilevazione.IdCommessa, s) },
        byKey: (s) => {
          if (s)
            return this.fetchLookUp('destinazioni/sottocommesse/', s);
        }

      })
    });



    //Validations
    this.validationRules = {
      required: [{ type: 'required', message: 'Campo obbligatorio' }],
      compareDateDa: [{ type: 'required', message: 'Campo obbligatorio' }, { type: 'compare', comparisonType: '<', comparisonTarget: () => this.rilevazione.AssenzaA }],
      compareDateA: [{ type: 'required', message: 'Campo obbligatorio' }, { type: 'compare', comparisonType: '>', comparisonTarget: () => this.rilevazione.AssenzaDa }],
      ore: [{ type: 'required', message: 'Campo obbligatorio' }, { type: 'compare', comparisonType: '>', comparisonTarget: () => 0 }]
    };




    this.IdTipoDestionazioneOptions = { dataSource: this.destinazioniDataSource, displayExpr: 'descrizione', valueExpr: 'id', searchEnabled: true, onValueChanged: this.onDataFieldChanged(this.setIdTipoDestinazione) };
    this.IdImbarcazioneOptions = { dataSource: this.imbarcazioniLookup, searchEnabled: true, displayExpr: 'descrizione', valueExpr: 'id', onValueChanged: this.onDataFieldChanged(this.setIdDestinazione) };
    this.IdCommessaOptions = { dataSource: this.commesseLookup, searchEnabled: true, displayExpr: 'descrizione', valueExpr: 'id', onValueChanged: this.onDataFieldChanged(this.resetIdSottocommessa) };
    this.IdCdCOptions = { dataSource: this.cdcLookup, displayExpr: 'descrizione', valueExpr: 'id', searchEnabled: true, onOpened: this.reloadOnOpen };
    this.IdSottocommessaOptions = { dataSource: this.sottodestinazioniLookup, displayExpr: 'descrizione', valueExpr: 'id', searchEnabled: true, onValueChanged: this.onDataFieldChanged(this.setIdSottocommessa) };
    this.IndefinitoOptions = { onValueChanged: this.onDataFieldChanged(this.setIndefinito) };

    this.IdLavoroOptions = { dataSource: this.lavoriDataSource, displayExpr: 'descrizione', valueExpr: 'id', searchEnabled: true, onValueChanged: this.onDataFieldChanged(this.setIdLavoro) };
    this.IdAttrezzaturaOptions = { dataSource: this.attrezzatureDataSource, displayExpr: 'descrizione', valueExpr: 'id', searchEnabled: true };
    this.DescrizioneLiberaOptions = { onValueChanged: this.descrizioneChanged };
    this.IdPersonaleOptions = { dataSource: this.personaleDataSource, displayExpr: 'nominativo', valueExpr: 'id', searchEnabled: true, onValueChanged: this.onDataFieldChanged(this.setIdPersona, true).bind(this) };
    this.IdCausaleOptions = { dataSource: this.causaliDataSource, displayExpr: 'descrizione', valueExpr: 'id', searchEnabled: true, onValueChanged: this.onDataFieldChanged(this.setIdCausale) };
    this.IdQualificaOptions = { dataSource: this.qualifichePersonale, displayExpr: 'descrizione', valueExpr: 'id', searchEnabled: true };

    this.state = { ready: false, commessaVisible: false, sottocommessaVisible: false, imbarcazioneVisible: false, cdcVisible: false, indefinitoVisible: false };

  }


  createDataSource(endPoint, onLoaded) {
    return new DataSource({
      store: new CustomStore({

        load: (s) => {
          return this.fetchJSON(endPoint).then(r => {
            if (s && s.searchValue)
              return r.filter((p) => p[s.searchExpr].match(new RegExp(s.searchValue + ".*", 'igm')))
            else
              return r;
          })
        },
        byKey: (s) => {
          if (s)
            return this.fetchJSON(endPoint, s);
          else
            return null;

        },
        onLoaded: (r) => {
          if (r.length > 0) {
            onLoaded(r);
          }
        }
      })
    });
  }


  fromStorage = (collection) => {
    return null;
    /*if (window.localStorage.getItem(collection)) {
      return JSON.parse(window.localStorage.getItem(collection));
    }
    return null;*/
  }

  lookupLoaded = () => {
    return true;// this.personale != null && this.causali != null && this.qualifiche != null && this.destinazioni != null && this.lavori != null && this.attrezzature != null;
  };




  /*
    fetchPersonale = (id) => { return this.fetchCollectionData(id, 'personale') };
    fetchQualifiche = (id) => { return this.fetchCollectionData(id, 'qualifiche') };
    fetchDestinazioni = (id) => { return this.fetchCollectionData(id, 'destinazioni') };
    fetchLavori = (id) => { return this.fetchCollectionData(id, 'lavori') };
    fetchCausali = (id) => { return this.fetchCollectionData(id, 'causali') };
    fetchAttrezzature = (id) => { return this.fetchCollectionData(id, 'attrezzature') };
  */
  fetchJSON = (endPoint, id) => {
    return fetch(appInfo.apiUrl() + endPoint + '/' + (id === undefined ? '' : id), appInfo.headers())
      .then((response) => {
        if (response.status !== 200) {
          return response.json()
            .then((r) => {
              return Promise.reject(r)
            });
        }
        return response.json()
      }).catch(function (ex) {
        notify('Errore durante il recupero delle informazioni [' + endPoint + ']', 'error');
        console.log(ex);
      });
  };

  /* fetchCollectionData = (id, endPoint) => {
     this.fetchJSON(endPoint,id)
       .then((r) => {
         this[endPoint] = r;
         window.localStorage.setItem(endPoint, JSON.stringify(r));
         if (this.lookupLoaded() && this.rilevazione.Id === 0) {
           this.setDefaultPersonale();
           this.setState({ ...this.state, ready: true });
         }
       }
       )
       .catch(function (ex) {
         notify('Errore durante il recupero delle informazioni [' + endPoint + ']', 'error');
         console.log(ex);
       });
   }*/


  fetchRilevazione = (id) => {
    this.loading = true;
    fetch(appInfo.apiUrl() + 'rilevazioni/' + id, appInfo.headers())
      .then(r => {

        if (r.status !== 200) {
          notify('Errore durante il caricamento della rilevazione:' + r.statusText, 'error');

        }
        else {
          return r.json();
        }
      })
      .then(r => {
        if (new URLSearchParams(this.props.location.search).get('clone')) {
          r.Id = 0;
          r.CanEdit = true;
        }

        this.rilevazione = r;

        this.setIdPersona(this.rilevazione.IdPersonale, false);
        this.setIdCausale(this.rilevazione.IdCausale);
        this.setIdTipoDestinazione(this.rilevazione.IdTipoDestinazione);
        this.setIdDestinazione(this.rilevazione.TipoDestinazione.Commessa ? this.rilevazione.IdCommessa : this.rilevazione.IdCdC);

        //if (this.lookupLoaded()) {
        this.setState({ ...this.state, ready: true });
        //}       
        this.loading = false;

      })
      .catch(e => {
        notify('Errore durante il caricamento della rilevazione:' + e.message, 'error');
        this.loading = false;
      });
  }




  fetchLookUp = (controller, id, loadOptions) => {
    let url = new URL(appInfo.apiUrl() + controller + '/' + ((id === undefined || id === null) ? '' : id));
    if (loadOptions !== undefined && loadOptions !== null) {
      let params = { s: loadOptions.searchValue };
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    }
    return fetch(url, appInfo.headers())
      .then((response) => {
        if (response.status !== 200) {
          return response.json()
            .then((r) => {
              return Promise.reject(r)
            });
        }
        return response.json();
      })
  }

  updateForm() {
    try {
      this.rilevazione.Updating = true;
      this.form.current.instance.option('formData', this.rilevazione);
      this.rilevazione.Updating = false;
    }
    catch (ex) {
      this.rilevazione.Updating = false;
    }
  }

  resetState() {

    this.form.current.instance.resetValues();
    Object.assign(this.rilevazione, this.defaultRilevazione);
    if (this.setDefaultPersonale())
      this.updateForm();
    this.form.current.instance.isValid = true;

    //this.setIdCausale();    
  }

  setDefaultPersonale() {
    if (this.personale && this.personale.length === 1 && !this.rilevazione.IdPersonale) {
      this.rilevazione.IdPersonale = this.personale[0].id;
      return this.setIdPersona(this.rilevazione.IdPersonale, true);
    }
    return false;

  }

  onDataFieldChanged = (setFieldLogic,arg) => {
    return (e) => {

      if (this.rilevazione.Updating || this.loading)
        return;
      if (setFieldLogic.call(this, e.value, arg)) {
        console.log(e.value);
        this.updateForm();
      }
    }
  }

  reloadOnOpen = (e) => { e.component.getDataSource().reload(); }

  setIdPersona(idpersona, setDefaults) {
    var needUpdate = false;
    if (idpersona && idpersona !== 0) {
      const persona = this.personale.find(p => p.id === idpersona);
      this.rilevazione.Persona = persona;
      this.qualifichePersonale.reload();

      if (setDefaults) {
        const qualifica = this.qualifiche.find(q => q.id === persona.qualifica.id);
        if (qualifica) {
          this.rilevazione.IdQualifica = qualifica.id;
          needUpdate = true;
        }



        if (persona && persona.centro_di_lavoro && persona.centro_di_lavoro.id) {
          this.rilevazione.IdCentroDiLavoro = persona.centro_di_lavoro.id;
          this.rilevazione.CentroDiLavoro = persona.centro_di_lavoro;
          needUpdate = true;
        }
      }
    }

    return needUpdate;
  }

  setIdCausale(idcausale) {
    return false;
    /* GESTIONE PANNELLO ASSENZE*/
    /*    if (idcausale && idcausale !== 0) {
        const causale = this.causali.find(c => c.id === idcausale);
        const presenza_assenza = causale.codice.startsWith('$') ? 'P' : 'A';
        this.form.current.instance.itemOption('Assenza', 'visible', presenza_assenza === 'A');
        this.form.current.instance.itemOption('Attività', 'visible', presenza_assenza === 'P');
        //return true;
      }
      else {
        this.form.current.instance.itemOption('Assenza', 'visible', false);
        this.form.current.instance.itemOption('Attività', 'visible', false);
      }
      return false;
    */
  }

  setIndefinito(indefinito) {
    if (indefinito) {
      this.rilevazione.IdSottocommessa = '';
      this.rilevazione.Sottocommessa = null;

    }
    return true;
  }


  setIdTipoDestinazione(IdTipoDestinazione) {
    var needUpdate = false;
    if (IdTipoDestinazione && !this.loading) {
      this.rilevazione.IdImbarcazione = '';
      this.rilevazione.IdCommessa = '';
      this.rilevazione.IdCdC = '';
      this.rilevazione.IdSottocommessa = '';
      this.rilevazione.Indefinito = false;
      needUpdate = true;
    }

    const tipoDestinazione = this.destinazioni.find(q => q.id === IdTipoDestinazione);
    if (tipoDestinazione) {
      this.setState({
        ...this.state,
        commessaVisible: tipoDestinazione.Tipo === "C",
        sottocommessaVisible: tipoDestinazione.Tipo === "C",
        cdcVisible: tipoDestinazione.Tipo === "D",
        imbarcazioneVisible: tipoDestinazione.Tipo === "I",
        indefinitoVisible: tipoDestinazione.Tipo === "C" && this.isResponsabile()
      });
      this.rilevazione.TipoDestinazione = tipoDestinazione;
    }

    return needUpdate;

  }
  setIdDestinazione(IdDestinazione) {
    if (IdDestinazione &&
      ((this.rilevazione.IdCdC && this.rilevazione.IdCdC !== IdDestinazione) ||
        (this.rilevazione.IdCommessa && this.rilevazione.IdCommessa !== IdDestinazione))) {
      this.rilevazione.IdSottocommessa = null;
      return true;
    }
    return false;

  }

  resetIdSottocommessa() {
    this.rilevazione.IdSottocommessa = null;
    return true;
  }


  setIdSottocommessa(IdSottocommessa) {
    if (IdSottocommessa != null && IdSottocommessa !== 0) {
      this.rilevazione.Indefinito = false;

      //this.form.current.instance.itemOption('Attività.Indefinito', 'checked', this.rilevazione.Indefinito);
      return true;
    }
    return false;
  }

  setIdLavoro(IdLavoro) {
    if (IdLavoro && IdLavoro !== 0 && !this.loading) {
      const lavoro = this.lavori.find(l => l.id === IdLavoro);
      this.rilevazione.Descrizione = lavoro.descrizione;
      return true;
    }
    return false;
  }

  setAssenzaDa(assenzaDa, assenzaA) {
    if (assenzaDa && assenzaA && new Date(assenzaA) < assenzaDa) {
      this.rilevazione.AssenzaA = roundTo30(addHours(assenzaDa, 1));
    }
  }

  salvaClick = (e) => {
    const val = this.form.current.instance.validate();
    if (val.isValid) {

      fetch(appInfo.apiUrl() + 'Rilevazioni',
        { method: 'POST', ...appInfo.headers(), body: JSON.stringify(this.rilevazione) }).then(
          r => {
            if (r.status === 200) {
              notify('Rilevazione salvata con successo', 'success');
              if (typeof (this.props.match.params.id) === 'undefined' || this.props.match.params.id === 0)
                this.props.history.push('/');
              else
                this.props.history.push('/rilevazioni');
            }
            else {
              throw (r);
            }
          }
        ).catch(
          e => e.json().then(l => notify(l.ExceptionMessage, 'error'))
        );
    }
  }


  eliminaClick = (e) => {
    if (this.rilevazione.Id !== 0) {
      confirm('Eliminare la rilevazione corrente?', 'Eliminazione').done(
        dr => {
          if (dr) {
            fetch(appInfo.apiUrl() + 'Rilevazioni/' + this.rilevazione.Id,
              { method: 'DELETE', ...appInfo.headers() }).then(
                r => {
                  if (r.status === 200) {
                    notify('Rilevazione eliminata con successo', 'success');
                    this.props.history.push('/rilevazioni');
                  }
                  else {
                    throw (r);
                  }
                }
              ).catch(e => e.json().then(l => notify(l.ExceptionMessage, 'error')));
          }
        });
    }
  }

  isResponsabile() {
    return JSON.parse(window.localStorage.me).Responsabile;
  }

  componentWillMount() {
  }


  componentDidMount() {
    this.qualificheDataSource.reload();
    this.personaleDataSource.reload();
    this.causaliDataSource.reload();
    this.lavoriDataSource.reload();
    this.destinazioniDataSource.reload();
    this.attrezzatureDataSource.reload();

    if (this.rilevazione.Id) {
      this.fetchRilevazione(this.rilevazione.Id);
    }
    else {
      this.setState({ ...this.state, ready: true });
    }
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {

  }




  render() {
    return (
      <React.Fragment >
        <h2 className={'content-block'}>Rilevazione</h2>
        <div className={'content-block dx-card responsive-paddings'}>
          <LoadPanel
            shadingColor={'rgba(0,0,0,0.4)'}
            visible={!this.state.ready}
            showIndicator={true}
            showPane={true}
            closeOnOutsideClick={false}
            ref={this.loadPanel}
          />


          <Form
            id={'form'}
            formData={this.rilevazione}
            labelLocation={'top'}
            minColWidth={233}
            colCount={3}
            //onFieldDataChanged={this.onFieldDataChanged}
            validationGroup={'validationRilevazione'}
            ref={this.form}
          >
            <GroupItem caption={'Generali'} id={'Generali'}>
              <Item dataField={'Id'} visible={false} />
              <Item dataField={'Data'} editorType={'dxDateBox'} editorOptions={{ dateSerializationFormat: 'yyyy-MM-dd' }} visible={true} validationRules={this.validationRules.required} />
              <Item dataField={'IdPersonale'} label={{ text: 'Persona' }} editorType={'dxSelectBox'} editorOptions={this.IdPersonaleOptions} validationRules={this.validationRules.required} />
              <Item dataField={'IdCausale'} label={{ text: 'Causale' }} editorType={'dxSelectBox'} editorOptions={this.IdCausaleOptions} validationRules={this.validationRules.required} />
              <Item dataField={'IdQualifica'} label={{ text: 'Qualifica' }} editorType={'dxSelectBox'} editorOptions={this.IdQualificaOptions} validationRules={this.validationRules.required} />
            </GroupItem>
            <GroupItem caption={'Assenza'} visible={false} >
              <Item dataField={'AssenzaDa'} editorType={'dxDateBox'} editorOptions={{ type: 'time', caption: 'Dalle' }} validationRules={this.validationRules.compareDateDa} />
              <Item dataField={'AssenzaA'} editorType={'dxDateBox'} editorOptions={{ type: 'time', caption: 'Alle' }} validationRules={this.validationRules.compareDateA} />
            </GroupItem>
            <GroupItem caption={'Attività'} visible={true}>
              <Item dataField={'Ore'} editorOptions={{ onValueChanged: this.oreChanged }} validationRules={this.validationRules.ore} />
              <Item dataField={'IdTipoDestinazione'} label={{ text: 'Destinazione' }} editorType={'dxSelectBox'} editorOptions={this.IdTipoDestionazioneOptions} validationRules={this.validationRules.required} />
              <Item dataField={'IdImbarcazione'} label={{ text: 'Imbarcazione' }} editorType={'dxSelectBox'} visible={this.state.imbarcazioneVisible} editorOptions={this.IdImbarcazioneOptions} validationRules={this.validationRules.required} />
              <Item dataField={'IdCommessa'} label={{ text: 'Commessa' }} editorType={'dxSelectBox'} visible={this.state.commessaVisible} editorOptions={this.IdCommessaOptions} validationRules={this.validationRules.required} />
              <Item dataField={'IdCdC'} label={{ text: 'Codice' }} editorType={'dxSelectBox'} visible={this.state.cdcVisible} editorOptions={this.IdCdCOptions} validationRules={this.validationRules.required} />
              <Item dataField={'IdSottocommessa'} label={{ text: 'Codice lavorazione' }} visible={this.state.sottocommessaVisible} editorType={'dxSelectBox'} editorOptions={this.IdSottocommessaOptions} />
              <Item dataField={'Indefinito'} label={{ text: 'Codice non trovato' }} visible={this.state.indefinitoVisible} editorType={'dxCheckBox'} editorOptions={this.IndefinitoOptions} />
              <Item dataField={'IdLavoro'} label={{ text: 'Descrizione standard' }} editorType={'dxSelectBox'} editorOptions={this.IdLavoroOptions} />
              <Item dataField={'Descrizione'} label={{ text: 'Descrizione libera' }} editorOptions={this.DescrizioneLiberaOptions} editorvalidationRules={this.validationRules.required} />
              <Item dataField={'IdAttrezzatura'} label={{ text: 'Mezzo' }} editorType={'dxSelectBox'} editorOptions={this.IdAttrezzaturaOptions} />
            </GroupItem>
          </Form>
          <Button text={'Salva'} type={'default'} visible={this.rilevazione.CanEdit} useSubmitBehavior={true} onClick={this.salvaClick} width={'33%'} />
          <span style={{ 'marginLeft': '20px' }}><Button text={'Elimina'} visible={this.rilevazione.CanDelete} type={'normal'} useSubmitBehavior={true} onClick={this.eliminaClick} width={'27%'} /></span>
        </div>
      </React.Fragment >
    );
  }
}
export default TimeSheetPage;