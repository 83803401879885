import { TimesheetPage, RilevazioniPage, StoricoPage } from './pages';

export default [
  {
    path: '/rilevazioni',
    component: RilevazioniPage
  },  
  {
    path: '/storico',
    component: StoricoPage
  },  
  {
    path: '/timesheet/:id',
    component: TimesheetPage   
  },
  {
    path: '/timesheet',
    component: TimesheetPage   
  }  
  ];




