export const navigation = [{
        text: 'Timesheet',
        path: '/timesheet',
        icon: 'clock'
    },
    {
        text: 'Rilevazioni',
        path: '/rilevazioni',
        icon: 'alignjustify'
    },
    {
        text: 'Storico',
        path: '/storico',
        icon: 'folder'
    }
];