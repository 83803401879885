import React, { useState } from 'react';
import './test.scss';
import { Form, Item, Label, RequiredRule, GroupItem } from 'devextreme-react/form';
import { Button } from 'devextreme-react/button';
import { Component } from 'devextreme-react/core/component';
import { appInfo } from '../../app-info';
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import { LoadPanel } from 'devextreme-react/load-panel';
import { addHours, roundTo30 } from '../../utils/date-utils';
import { Route } from 'devextreme-react/map';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog'

class TestPage extends Component {


  constructor(props) {
    super(props);
    this.state = {
      IdPersonale: '',
      IdQualifica: ''
    };

    //State
    //Refs
    this.form = React.createRef();





    //Collections
    this.personale = this.fromStorage('personale');
    this.causali = this.fromStorage('causali');
    this.qualifiche = this.fromStorage('qualifiche');
    this.destinazioni = this.fromStorage('destinazioni');
    this.lavori = this.fromStorage('lavori');
    this.attrezzature = this.fromStorage('attrezzature');



    //Validations
    this.validationRules = {
      required: [{ type: 'required', message: 'Campo obbligatorio' }],
      compareDateDa: [{ type: 'required', message: 'Campo obbligatorio' }, { type: 'compare', comparisonType: '<', comparisonTarget: () => this.state.AssenzaA }],
      compareDateA: [{ type: 'required', message: 'Campo obbligatorio' }, { type: 'compare', comparisonType: '>', comparisonTarget: () => this.state.AssenzaDa }],
      ore: [{ type: 'required', message: 'Campo obbligatorio' }, { type: 'compare', comparisonType: '>', comparisonTarget: () => 0 }]
    };






  }


  fetchPersonale = (id) => { return this.fetchCollectionData(id, 'personale') };
  fetchQualifiche = (id) => { return this.fetchCollectionData(id, 'qualifiche') };
  fetchDestinazioni = (id) => { return this.fetchCollectionData(id, 'destinazioni') };
  fetchLavori = (id) => { return this.fetchCollectionData(id, 'lavori') };
  fetchCausali = (id) => { return this.fetchCollectionData(id, 'causali') };
  fetchAttrezzature = (id) => { return this.fetchCollectionData(id, 'attrezzature') };


  fromStorage = (collection) => {
    if (window.localStorage.getItem(collection)) {
      return JSON.parse(window.localStorage.getItem(collection));
    }
    return null;
  }
  fetchLookUp = (controller, id, loadOptions) => {
    let url = new URL(appInfo.apiUrl() + controller + '/' + ((id === undefined || id === null) ? '' : id));
    if (loadOptions !== undefined && loadOptions !== null) {
      let params = { s: loadOptions.searchValue };
      Object.keys(params).forEach(key => url.searchParams.append(key, params[key]));
    }
    return fetch(url, appInfo.headers())
      .then((response) => {
        if (response.status !== 200) {
          return response.json()
            .then((r) => {
              return Promise.reject(r)
            });
        }
        return response.json();
      })
  }
  fetchCollectionData = (id, endPoint) => {
    fetch(appInfo.apiUrl() + endPoint + '/' + (id === undefined || id === null ? '' : id), appInfo.headers())
      .then((response) => {
        if (response.status !== 200) {
          return response.json()
            .then((r) => {
              return Promise.reject(r)
            });
        }
        return response.json()
      })
      .then((r) => {
        this[endPoint] = r;
        window.localStorage.setItem(endPoint, JSON.stringify(r));
        this.setState({ ready: true });
      }
      )
      .catch(function (ex) {
        notify('Errore durante il recupero delle informazioni [' + endPoint + ']' + ex, 'error');
        console.log(ex);
      });
  }

  componentWillMount() {
  }


  componentDidMount() {
    if (!this.personale) this.fetchPersonale();
    if (!this.causali) this.fetchCausali();
    if (!this.qualifiche) this.fetchQualifiche();
    if (!this.destinazioni) this.fetchDestinazioni();
    if (!this.lavori) this.fetchLavori();
    if (!this.attrezzature) this.fetchAttrezzature();

  }

  componentDidUpdate() {
  }

  componentWillUnmount() {

  }

  salvaClick() {
    console.log(this.state);
  }

  onIdPersonaleChanged(e) {
      const persona = this.personale.find(p => p.id === e.value);
      const qualifica = this.qualifiche.find(q => q.id === persona.qualifica.id);
      var IdQualifica = null;
      if (qualifica) {
        IdQualifica = qualifica.id;
      }
      this.state.IdQualifica= IdQualifica ;
      this.form.current.instance.option('formData',this.state);
      
      

  }

  render() {
    return (
      <React.Fragment >
        <h2 className={'content-block'}>Persona</h2>
        <div className={'content-block dx-card responsive-paddings'}>
          
          <Form
            id={'form'}
            formData={this.state}
            labelLocation={'top'}
            minColWidth={233}
            //onFieldDataChanged={this.onFieldDataChanged.bind(this)}
            ref={this.form}
          >

            <Item dataField={'Id'} visible={false} />

            <GroupItem caption={'Generali'} id={'Generali'}>
              <Item dataField={'Id'} visible={false} />
              <Item dataField={'Data'} editorType={'dxDateBox'} editorOptions={{ dateSerializationFormat: 'yyyy-MM-dd' }} visible={true} validationRules={this.validationRules.required} />
              <Item dataField={'IdPersonale'} label={{ text: 'Persona' }} editorType={'dxSelectBox'} editorOptions={{ dataSource: this.personale, displayExpr: 'nominativo', valueExpr: 'id', searchEnabled: true, onValueChanged:this.onIdPersonaleChanged.bind(this) }} validationRules={this.validationRules.required} />
              <Item dataField={'IdCausale'} label={{ text: 'Causale' }} editorType={'dxSelectBox'} editorOptions={{ dataSource: this.causali, displayExpr: 'descrizione', valueExpr: 'id', searchEnabled: true }} validationRules={this.validationRules.required} />
              <Item dataField={'IdQualifica'} label={{ text: 'Qualifica' }} editorType={'dxSelectBox'} editorOptions={{ dataSource: this.qualifiche, displayExpr: 'descrizione', valueExpr: 'id', searchEnabled: true }} validationRules={this.validationRules.required} />
            </GroupItem>
            <GroupItem caption={'Assenza'} visible={false} >
              <Item dataField={'AssenzaDa'} editorType={'dxDateBox'} editorOptions={{ type: 'time', caption: 'Dalle' }} validationRules={this.validationRules.compareDateDa} />
              <Item dataField={'AssenzaA'} editorType={'dxDateBox'} editorOptions={{ type: 'time', caption: 'Alle' }} validationRules={this.validationRules.compareDateA} />
            </GroupItem>
             <GroupItem caption={'Attività'} visible={true}>
              <Item dataField={'Ore'} editorOptions={{ onValueChanged: this.oreChanged }} validationRules={this.validationRules.ore} />
              <Item dataField={'IdTipoDestinazione'} label={{ text: 'Tipo dest.' }} editorType={'dxSelectBox'} editorOptions={{ dataSource: this.destinazioni, displayExpr: 'descrizione', valueExpr: 'id', searchEnabled: true }} validationRules={this.validationRules.required} />
              <Item dataField={'IdDestinazione'} label={{ text: 'Destinazione' }} editorType={'dxSelectBox'} editorOptions={{ dataSource: this.destinazioniLookup, displayExpr: 'descrizione', valueExpr: 'id', searchEnabled: true }} validationRules={this.validationRules.required} />
              <Item dataField={'IdSottocommessa'} label={{ text: 'Codice lavorazione' }} editorType={'dxSelectBox'} editorOptions={{ dataSource: this.sottodestinazioniLookup, displayExpr: 'descrizione', valueExpr: 'id', searchEnabled: true }} />
              <Item dataField={'IdLavoro'} label={{ text: 'Lavoro' }} editorType={'dxSelectBox'} editorOptions={{ dataSource: this.lavori, displayExpr: 'descrizione', valueExpr: 'id', searchEnabled: true }} />
              <Item dataField={'Descrizione'} editorOptions={{ onValueChanged: this.descrizioneChanged }} validationRules={this.validationRules.required} />
              <Item dataField={'IdAttrezzatura'} label={{ text: 'Mezzo' }} editorType={'dxSelectBox'} editorOptions={{ dataSource: this.attrezzature, displayExpr: 'descrizione', valueExpr: 'id', searchEnabled: true }} />
            </GroupItem> 
          </Form>
          <Button text={'Salva'} type={'default'} useSubmitBehavior={true} onClick={this.salvaClick.bind(this)} width={'33%'} />
        </div>
      </React.Fragment >
    );
  }
}
export default TestPage; 