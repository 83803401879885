import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import 'devextreme/dist/css/dx.common.css';
import React, { Component } from 'react';
import { HashRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { appInfo } from './app-info';
import { navigation } from './app-navigation';
import routes from './app-routes';
import './App.scss';
import './dx-styles.scss';
import { Footer, LoginForm } from './components';
import {
  SideNavOuterToolbar as SideNavBarLayout,
  SingleCard
} from './layouts';
import { sizes, subscribe, unsubscribe } from './utils/media-query';
import notify from 'devextreme/ui/notify';
import { AuthProvider, AuthConsumer } from 'react-check-auth'
import { timingSafeEqual } from 'crypto';
import "devextreme-intl";
import { locale, loadMessages } from "devextreme/localization";
import itMessages from './i18n/it';


const LoginContainer = ({ logIn }) => <LoginForm onLoginClick={logIn} />;

const NotAuthPage = (props) => (
  <SingleCard>
    <Route render={() => <LoginContainer {...props} />} />
  </SingleCard>
);

const AuthPage = (props) => (
  <SideNavBarLayout menuItems={navigation} title={appInfo.title} {...props}>
    <Switch>
      {routes.map(item => (
        <Route
          exact
          key={item.path}
          path={item.path}
          component={item.component}
        />
      ))}
      <Redirect to={'/timesheet'} />
    </Switch>
    <Footer>
      Copyright © 2019 Tech s.r.l.      
    </Footer>
  </SideNavBarLayout>
);

class App extends Component {
  constructor(props) {
    super(props);


    this.refreshAuth = null;
   
    this.state = {
      screenSizeClass: this.getScreenSizeClass(),
      appInfo: appInfo
    };

    this.userMenuItems = [
      // {
      //   text: 'Profile',
      //   icon: 'user'
      // },
      {
        text: 'Logout',
        icon: 'runner',
        onClick: this.logOut
      }
    ];
  }


  componentDidMount() {
    subscribe(this.screenSizeChanged);
    loadMessages(itMessages);
    locale(navigator.language || navigator.browserLanguage);
  }

  componentDidUpdate() {
  }

  componentWillUnmount() {
    unsubscribe(this.screenSizeChanged);
  }

  render() {

    return (
      <AuthProvider authUrl={this.state.appInfo.apiUrl() + 'personale/me'} reqOptions={this.reqOptions.bind(this)}>
        <div className={`app ${this.state.screenSizeClass}`}>
          <AuthConsumer >
            {({ isLoading, userInfo, error, refreshAuth }) => {
              this.refreshAuth = refreshAuth;
              window.localStorage.setItem('me',JSON.stringify(userInfo));
              if (!userInfo) {
                return (<Router><NotAuthPage logIn={this.logIn} /></Router>)
              }
              else {
                return (<Router><AuthPage userMenuItems={this.userMenuItems} /></Router>)
              }
            }
            }

          </AuthConsumer>
        </div>

      </AuthProvider>
    );
  }

  reqOptions() {
    return { method: 'GET', ...this.state.appInfo.headers() };
    //return { 'method': 'GET', 'headers': {'Authorization' : 'Bearer ' + window.localStorage.getItem('token')} };
  }

  getScreenSizeClass() {
    const screenSizes = sizes();
    return Object.keys(screenSizes).filter(cl => screenSizes[cl]).join(' ');
  }

  screenSizeChanged = () => {
    this.setState({
      screenSizeClass: this.getScreenSizeClass()
    });
  }

  logIn = (pin, refreshAuth) => {
    const data = new URLSearchParams();
    data.append('grant_type', 'password');
    data.append('username', pin);
    data.append('password', pin);
    fetch(this.state.appInfo.baseUrl + 'token',
      {
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: data
      })
      .then(r => {
        if (r.status === 200)
          return r.json();
      })
      .then(r => {
        window.localStorage.setItem('token', r.access_token);
        this.refreshAuth();
      })
      .catch(e => {
        notify('PIN non valido', 'error')
      }
      );
  };

  logOut = () => {
    window.localStorage.clear();
    this.refreshAuth();
  };
}

export default App;
