import React from 'react';
import 'devextreme/data/odata/store';
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import DataGrid, {
    Column,
    Pager,
    Paging,
    FilterRow,
    Lookup,
    Summary,
    TotalItem
} from 'devextreme-react/data-grid';
import { appInfo } from '../../app-info';
import notify from 'devextreme/ui/notify';
import { confirm } from 'devextreme/ui/dialog'
import { Editing } from 'devextreme-react/tree-list';

export default class extends React.Component {

    dataSource = new DataSource({
        store: new CustomStore({
            key: 'Id',
            load: s => {
                if (this.isResponsabile()) {
                    return fetch(appInfo.apiUrl() + 'Rilevazioni/Storico', { ...appInfo.headers() })
                        .then(r => {
                            if (r.status !== 200) {
                                notify('Errore nel caricamento delle rilevazioni', 'error');
                            }
                            else
                                return r.json();
                        }
                        );
                }
            }
        })
    });


    isResponsabile() {
        return JSON.parse(window.localStorage.me).Responsabile;
    }

  rowPrepared(e) {
        if (e.rowType === 'data') {
            if (e.data.Eliminato) e.rowElement.style.textDecoration = 'line-through';
            if (e.data.Approvato) e.rowElement.style.fontWeight = 'bold';


        }
    }


    render() {
        return (
            <React.Fragment>
                <h2 className={'content-block'}>Rilevazioni</h2>

                <DataGrid
                    className={'dx-card wide-card'}
                    dataSource={this.dataSource}
                    showBorders={false}
                    focusedRowEnabled={true}
                    defaultFocusedRowIndex={0}
                    columnAutoWidth={true}
                    onRowDblClick={this.editClick}
                    onRowPrepared={this.rowPrepared}
                    onCellPrepared={this.cellPrepared}
                >
                    <Editing mode={'Row'} allowDeleting={this.allowDeleting} />
                    <Paging defaultPageSize={10} />
                    <Pager showPageSizeSelector={true} showInfo={true} />
                    <FilterRow visible={true} />
                    <Column dataField={'Id'} visible={false} />
                    <Column
                        dataField={'Data'} dataType={'date'} format={'dd/MM/yyyy'}
                    />
                    <Column
                        dataField={'Ore'}
                        caption={'Ore'}
                        datatype={'decimal'}
                        format={'#,##0.00'}
                    />
                    <Column
                        dataField={'Persona.nominativo'}
                        caption={'Personale'}
                        visible={this.isResponsabile()}
                    />
                    <Column
                        dataField={'Qualifica.descrizione'}
                        caption={'Qualifica'}
                        visible={this.isResponsabile()}
                    />
                    <Column
                        dataField={'Causale.descrizione'}
                        caption={'Causale'}
                    />

                    <Column
                        dataField={'Destinazione'}
                        caption={'Destinazione'}
                    />

                    <Column
                        dataField={'Codice'}
                        caption={'Codice'}
                    />
                    <Column
                        dataField={'Descrizione'}
                        caption={'Descrizione'}
                    />
                    <Column dataField={'OreEffettive'} visible={false}>

                    </Column>
                    <Column
                        dataField={'Attrezzatura.codice'}
                        caption={'Mezzo'}
                    />
                    <Summary>
                        <TotalItem
                            column={'OreEffettive'}
                            summaryType={'sum'}
                            displayFormat={'{0}'}
                            valueFormat={'#,##0.00'}
                            showInColumn={'Ore'} />
                    </Summary>
                </DataGrid>
            </React.Fragment>
        );
    }
}