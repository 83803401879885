export function addHours(date, hours) {
    return new Date(date).setHours(date.getHours() + hours)
}

export function roundTo30(date)
{
    let d=new Date(date);
    if(d.getMinutes()<30) 
    {
        d.setMinutes(0);
    }
    else if(d.getMinutes()>30) 
     {
        d.setMinutes(30);     
     }   
     return d;
}